<template>
  <el-dialog
    :visible.sync="visible"
    :title="title"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="80%"
    @close="closeHandle"
  >
    <div class="handle-content">
      <div class="device-control-list-content">
        <el-table
          class="mt20 ft14 c333 it1"
          style="width: 100%"
          height="100%"
          highlight-current-row
          stripe
          :data="batteryTypeTop"
          key="MenuList"
        >
          <el-table-column prop="sn" label="设备编号">
            <template slot-scope="scope">
              {{scope.row.sn}}
            </template>
          </el-table-column>
          <el-table-column prop="status" label="IMei">
            <template slot-scope="scope">
              {{scope.row.imei}}
            </template>
          </el-table-column>
          <el-table-column prop="cmd" label="报警时间">
            <template slot-scope="scope">
              {{scope.row.alarmTime}}
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="时长">
            <template slot-scope="scope">
              {{scope.row.interval}}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <template>
        <div style="color: #2e9afe; font-size: 12px; margin-top: 30px;">
          【电池类型周故障数】
        </div>
        <div
          ref="weekAllCharts"
          style="width: 100%; height: 300px"
        ></div>
      </template>
    </div>
    <template slot="footer">
      <el-button @click="visible = false">取消</el-button>
    </template>
  </el-dialog>
</template>
<script>
let echarts = require("echarts/lib/echarts");
// 引入柱状图组件
require("echarts/lib/chart/bar");
require("echarts/lib/chart/line");
// 引入提示框和title组件
require("echarts/lib/component/tooltip");
require("echarts/lib/component/title");
export default {
  data() {
    return {
      title: null,
      loading: false,
      visible: false,
      batteryTypeTop: [],
      batteryWeekTimes: [],
      batteryWeekDatas: []
    };
  },
  computed: {
    rules() {
      return {
        name: [
          { required: true, message: "必填项不能为空", trigger: "blur" },
        ],
        mobile: [
          { required: true, message: "必填项不能为空", trigger: "blur" },
          { validator: this.validatePhoneNumber, trigger: 'blur' }
        ],
        level: [
          { required: true, message: "必填项不能为空", trigger: "change" },
        ],
        types: [
          { required: true, message: "必填项不能为空", trigger: "change" },
        ],
        channels: [
          { required: true, message: "必填项不能为空", trigger: "change" },
        ]
      };
    },
  },
  methods: {
    //初始化
    init() {
      this.visible = true;
      this.$nextTick(() => {
        this.getDataInfo()
      });
    },
    closeHandle() {
      this.batteryTypeTop = []
      this.batteryWeekTimes = []
      this.batteryWeekDatas = []
    },
    getDataInfo(){
      let times = this.batteryWeekTimes || []
      let list = this.batteryWeekDatas || []
      let weekAllCharts = echarts.init(this.$refs.weekAllCharts);
      if(list.length > 0) {
        weekAllCharts.setOption({
          tooltip: {
            //鼠标悬浮框的提示文字
            trigger: "axis",
          },
          legend: {
            data: ["处理人未完结数"],
            textStyle: {
              //图例文字的样式
              color: "#000",
              fontSize: 10,
            },
          },
          dataZoom: [
            {
              type: "slider",
              start: 0,
              end: 100,
            },
            {
              type: "inside",
              start: 0,
              end: 100,
            },
          ],
          xAxis: {
            data: times,
          },
          yAxis: {
            type: "value"
          },

          series: [
            {
              data: list,
              type: "line",
              smooth: true,
            },
          ],
        })
      } else {
        this.$nextTick(() => {
          const dom = this.$refs.weekAllCharts;
          dom.innerHTML =
            '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
          dom.removeAttribute("_echarts_instance_");
        });
      }
    },
  },
};
</script>