<template>
  <div class="statist-container">
    <div class="top-stat-data">
      <div class="low-week">
        <div class="low-week-title">低电量报警数</div>
        <div class="low-week-data">{{pageData.lowPowerAlarmDto && pageData.lowPowerAlarmDto.lowPowerAlarmOverTotal || 0}}</div>
      </div>
      <div class="low-week">
        <div class="low-week-title">低电量报警完结数</div>
        <div class="low-week-data">{{pageData.lowPowerAlarmDto && pageData.lowPowerAlarmDto.lowPowerAlarmTotal || 0}}</div>
      </div>
      <div class="off-line-week">
        <div class="off-line-week-title">离线报警数</div>
        <div class="off-line-week-data">{{pageData.offLineAlarmDto && pageData.offLineAlarmDto.offLineAlarmTotal || 0}}</div>
      </div>
      <div class="off-line-week">
        <div class="off-line-week-title">离线报警完结数</div>
        <div class="off-line-week-data">{{pageData.offLineAlarmDto && pageData.offLineAlarmDto.offLineAlarmOverTotal || 0}}</div>
      </div>
      <div class="base-alarm">
        <div class="base-alarm-title">常规报警数</div>
        <div class="base-alarm-data">{{pageData.faultSummaryDto && pageData.faultSummaryDto.faultTotalCount || 0}}</div>
      </div>
      <div class="base-alarm">
        <div class="base-alarm-title">常规报警完结数</div>
        <div class="base-alarm-data">{{pageData.faultSummaryDto && pageData.faultSummaryDto.faultTotalOverCount || 0}}</div>
      </div>
      <div class="hot-alarm">
        <div class="hot-alarm-title">热失控报警数</div>
        <div class="hot-alarm-data">{{pageData.faultSummaryDto && pageData.faultSummaryDto.cloudControlAlarmDistributionDto && pageData.faultSummaryDto.cloudControlAlarmDistributionDto.alarmCount || 0}}</div>
      </div>
      <div class="hot-alarm">
        <div class="hot-alarm-title">热失控预警数</div>
        <div class="hot-alarm-data">{{pageData.faultSummaryDto && pageData.faultSummaryDto.cloudControlAlarmDistributionDto && pageData.faultSummaryDto.cloudControlAlarmDistributionDto.earlyWarningCount || 0}}</div>
      </div>
    </div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="常规报警" name="first">
        <template>
          <div style="color: #2e9afe; font-size: 12px;">
            【周总完结率】
          </div>
          <div
            ref="weekAllCharts"
            style="width: 100%; height: 300px"
          ></div>
        </template>
        <template>
          <div style="color: #2e9afe; font-size: 12px;">
            【周完结率】
          </div>
          <div
            ref="weekOverCharts"
            style="width: 100%; height: 300px;"
          ></div>
        </template>
        <template>
          <div style="color: #2e9afe; font-size: 12px;">
            【故障类型未完结数】
          </div>
          <div
            ref="faultCharts"
            style="width: 100%; height: 300px;"
          ></div>
        </template>
        <template>
          <div style="color: #2e9afe; font-size: 12px;">
            【处理人未完结数】
          </div>
          <div
            ref="personCharts"
            style="width: 100%; height: 300px; margin-bottom: 50px;"
          ></div>
        </template>
        
      </el-tab-pane>
      <el-tab-pane label="离线报警" name="second">
        <div class="device-control-list-content">
          <el-table
            class="mt20 ft14 c333 it1"
            style="width: 100%"
            height="100%"
            highlight-current-row
            stripe
            :data="pageData.offLineAlarmDto && pageData.offLineAlarmDto.alarmBatteryDto"
            key="MenuList"
          >
            <el-table-column prop="sn" label="设备编号">
              <template slot-scope="scope">
                {{scope.row.sn}}
              </template>
            </el-table-column>
            <el-table-column prop="status" label="IMei">
              <template slot-scope="scope">
                {{scope.row.imei}}
              </template>
            </el-table-column>
            <el-table-column prop="cmd" label="报警时间">
              <template slot-scope="scope">
                {{scope.row.alarmTime}}
              </template>
            </el-table-column>
            <el-table-column prop="createTime" label="时长">
              <template slot-scope="scope">
                {{scope.row.interval}}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="低电量报警" name="third">
        <div class="device-control-list-content">
          <el-table
            class="mt20 ft14 c333 it1"
            style="width: 100%"
            height="100%"
            highlight-current-row
            stripe
            :data="pageData.lowPowerAlarmDto && pageData.lowPowerAlarmDto.lowPowerBatteryDtoList"
            key="MenuList"
          >
            <el-table-column prop="sn" label="设备编号">
              <template slot-scope="scope">
                {{scope.row.sn}}
              </template>
            </el-table-column>
            <el-table-column prop="status" label="IMei">
              <template slot-scope="scope">
                {{scope.row.imei}}
              </template>
            </el-table-column>
            <el-table-column prop="cmd" label="报警时间">
              <template slot-scope="scope">
                {{scope.row.alarmTime}}
              </template>
            </el-table-column>
            <el-table-column prop="createTime" label="时长">
              <template slot-scope="scope">
                {{scope.row.interval}}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="类型电池信息" name="fourth">
        <div class="device-control-list-content">
          <el-table
            class="mt20 ft14 c333 it1"
            style="width: 100%"
            height="100%"
            highlight-current-row
            stripe
            :data="pageData.batterySummaryDto && pageData.batterySummaryDto.batteryTypeSummaryFaultDtoList"
            key="MenuList"
          >
            <el-table-column prop="sn" label="设备类型">
              <template slot-scope="scope">
                {{scope.row.batteryType}}
              </template>
            </el-table-column>
            <el-table-column prop="sn" label="设备名称">
              <template slot-scope="scope">
                {{scope.row.batteryTypeText || '-'}}
              </template>
            </el-table-column>
            <el-table-column prop="status" label="故障数量">
              <template slot-scope="scope">
                {{scope.row.count}}
              </template>
            </el-table-column>
            <el-table-column prop="createTime" label="操作">
              <template slot-scope="scope">
                <el-button @click="infoHandle(scope.row)" type="text"
                  >查看</el-button
                >
              </template>
            </el-table-column>
            
          </el-table>
        </div>
      </el-tab-pane>
    </el-tabs>
    <InfoModel v-if="visable" ref="infomodel" />
  </div>
</template>
<script>
import "./Index.less"
import axios from "@/utils/axios";
let echarts = require("echarts/lib/echarts");
// 引入柱状图组件
require("echarts/lib/chart/bar");
require("echarts/lib/chart/line");
// 引入提示框和title组件
require("echarts/lib/component/tooltip");
require("echarts/lib/component/title");
import InfoModel from "./components/Info-model"
export default {
  name: "WeekStatisti",
  components: {
    InfoModel,
  },
  data() {
    return {
      activeName: 'first',
      pageData: {},
      weekAllTimes: [],
      weekAllDatas: [],
      weekOverTimes: [],
      weekOverDatas: [],
      faultCodes: [],
      unDoneCounts: [],
      persons: [],
      personCounts: [],
      batteryTypeTop: [],
      batteryWeeks: [],
      visable: false,
    }
  },
  mounted() {
    this.getDataInfo()
  },
  methods: {
    infoHandle(row) {
      this.visable = true;
      let batteryTypeTopFive = []
      let batteryTypeTop = this.batteryTypeTop || []
      if(batteryTypeTop.length > 0) {
        for(var i = 0; i < batteryTypeTop.length; i++) {
          if(batteryTypeTop[i].batteryType === row.batteryType) {
            batteryTypeTopFive = batteryTypeTop[i].alarmBatteryList
          }
        }
      }
      let batteryWeekTimes = []
      let batteryWeekDatas = []
      let batteryWeeks = this.batteryWeeks || []
      if(batteryWeeks.length > 0) {
        for(var i = 0; i < batteryWeeks.length; i++) {
          if(batteryWeeks[i].batteryType === row.batteryType) {
            batteryWeekTimes = batteryWeeks[i].weekIndexList
            batteryWeekDatas = batteryWeeks[i].batteryTypeAlarmList
          }
        }
      }
      this.$nextTick(() => {
        this.$refs.infomodel.init();
        this.$refs.infomodel.title = '电池类型查看';
        this.$refs.infomodel.batteryTypeTop = batteryTypeTopFive
        this.$refs.infomodel.batteryWeekTimes = batteryWeekTimes
        this.$refs.infomodel.batteryWeekDatas = batteryWeekDatas
      });
    },
    handleClick(tab, event) {
      this.activeName = tab.name
      if(tab.name === 'first') {
        this.drawWeekAllCharts(this.weekAllTimes, this.weekAllDatas)
        this.drawWeekOverCharts(this.weekOverTimes, this.weekOverDatas)
        this.drawFaultCharts(this.faultCodes, this.unDoneCounts)
        this.drawPersonCharts(this.persons, this.personCounts)
      }
    },
    getDataInfo() {
      axios
        .post("/api/quantityBoard/getData", {organId: localStorage.getItem("organId")})
        .then((res) => {
          this.pageData = res
          let faultSummaryDto = res.faultSummaryDto || {}
          let completionRateTypeList = faultSummaryDto.completionRateTypeList || []
          if(completionRateTypeList[0] && completionRateTypeList[0].type === 0) {
            this.weekAllTimes = completionRateTypeList[0].timeList
            this.weekAllDatas = completionRateTypeList[0].rateList
            this.$nextTick(() => {
              this.drawWeekAllCharts(completionRateTypeList[0].timeList, completionRateTypeList[0].rateList)
            })
          } else if(completionRateTypeList[0] && completionRateTypeList[0].type === 1) {
            this.weekOverTimes = completionRateTypeList[0].timeList
            this.weekOverDatas = completionRateTypeList[0].rateList
            this.$nextTick(() => {
              this.drawWeekOverCharts(completionRateTypeList[0].timeList, completionRateTypeList[0].rateList)
            })
          }
          if(completionRateTypeList[1] && completionRateTypeList[1].type === 0) {
            this.weekAllTimes = completionRateTypeList[0].timeList
            this.weekAllDatas = completionRateTypeList[0].rateList
            this.$nextTick(() => {
              this.drawWeekAllCharts(completionRateTypeList[1].timeList, completionRateTypeList[1].rateList)
            })
          } else if(completionRateTypeList[1] && completionRateTypeList[1].type === 1) {
            this.weekOverTimes = completionRateTypeList[0].timeList
            this.weekOverDatas = completionRateTypeList[0].rateList
            this.$nextTick(() => {
              this.drawWeekOverCharts(completionRateTypeList[1].timeList, completionRateTypeList[1].rateList)
            })
          }

          let undoneDistributionDtoList = faultSummaryDto.undoneDistributionDtoList || []
          if(undoneDistributionDtoList && undoneDistributionDtoList.length > 0) {
            let faultCodes = []
            let unDoneCounts = []
            let persons = []
            let personCounts = []
            for(var i = 0; i < undoneDistributionDtoList.length; i++) {
              if(undoneDistributionDtoList[i].type === 0) {
                faultCodes.push(undoneDistributionDtoList[i].faultCode)
                unDoneCounts.push(undoneDistributionDtoList[i].unDoneCount)
              } else {
                persons.push(undoneDistributionDtoList[i].currentPersonText)
                personCounts.push(undoneDistributionDtoList[i].unDoneCount)
              }
            }
            this.faultCodes = faultCodes
            this.unDoneCounts = unDoneCounts
            this.persons = persons
            this.personCounts = personCounts
            this.$nextTick(() => {
              this.drawFaultCharts(faultCodes, unDoneCounts)
            })
            this.$nextTick(() => {
              this.drawPersonCharts(persons, personCounts)
            })
          }
          this.batteryTypeTop = res.batterySummaryDto && res.batterySummaryDto.batteryTypeAndWeekDiagramDto && res.batterySummaryDto.batteryTypeAndWeekDiagramDto.batteryTypeDiagramDto || []
          this.batteryWeeks = res.batterySummaryDto && res.batterySummaryDto.batteryTypeAndWeekDiagramDto && res.batterySummaryDto.batteryTypeAndWeekDiagramDto.batteryTypeAlarmGridDtoList || []
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    
    drawPersonCharts(times, list) {
      let personCharts = echarts.init(this.$refs.personCharts);
      if(list.length > 0) {
        personCharts.setOption({
          tooltip: {
            //鼠标悬浮框的提示文字
            trigger: "axis",
          },
          legend: {
            data: ["处理人未完结数"],
            textStyle: {
              //图例文字的样式
              color: "#000",
              fontSize: 10,
            },
          },
          dataZoom: [
            {
              type: "slider",
              start: 0,
              end: 100,
            },
            {
              type: "inside",
              start: 0,
              end: 100,
            },
          ],
          xAxis: {
            data: times,
          },
          yAxis: {
            type: "value"
          },

          series: [
            {
              data: list,
              type: "line",
              smooth: true,
            },
          ],
        })
      } else {
        this.$nextTick(() => {
          const dom = this.$refs.personCharts;
          dom.innerHTML =
            '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
          dom.removeAttribute("_echarts_instance_");
        });
      }
    },
    drawFaultCharts(times, list) {
      let faultCharts = echarts.init(this.$refs.faultCharts);
      if(list.length > 0) {
        faultCharts.setOption({
          tooltip: {
            //鼠标悬浮框的提示文字
            trigger: "axis",
          },
          legend: {
            data: ["故障未完结数"],
            textStyle: {
              //图例文字的样式
              color: "#000",
              fontSize: 10,
            },
          },
          dataZoom: [
            {
              type: "slider",
              start: 0,
              end: 100,
            },
            {
              type: "inside",
              start: 0,
              end: 100,
            },
          ],
          xAxis: {
            data: times,
          },
          yAxis: {
            type: "value"
          },

          series: [
            {
              data: list,
              type: "line",
              smooth: true,
            },
          ],
        })
      } else {
        this.$nextTick(() => {
          const dom = this.$refs.faultCharts;
          dom.innerHTML =
            '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
          dom.removeAttribute("_echarts_instance_");
        });
      }
    },
    drawWeekOverCharts(times, list) {
      let weekOverCharts = echarts.init(this.$refs.weekOverCharts);
      if(list.length > 0) {
        weekOverCharts.setOption({
          tooltip: {
            //鼠标悬浮框的提示文字
            trigger: "axis",
          },
          legend: {
            data: ["周完结率"],
            textStyle: {
              //图例文字的样式
              color: "#000",
              fontSize: 10,
            },
          },
          dataZoom: [
            {
              type: "slider",
              start: 0,
              end: 100,
            },
            {
              type: "inside",
              start: 0,
              end: 100,
            },
          ],
          xAxis: {
            data: times,
          },
          yAxis: {
            type: "value"
          },

          series: [
            {
              data: list,
              type: "line",
              smooth: true,
            },
          ],
        })
      } else {
        this.$nextTick(() => {
          const dom = this.$refs.weekOverCharts;
          dom.innerHTML =
            '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
          dom.removeAttribute("_echarts_instance_");
        });
      }
    },
    drawWeekAllCharts(times, list) {
      let weekAllCharts = echarts.init(this.$refs.weekAllCharts);
      if(list.length > 0) {
        weekAllCharts.setOption({
          tooltip: {
            //鼠标悬浮框的提示文字
            trigger: "axis",
          },
          legend: {
            data: ["周总完结率"],
            textStyle: {
              //图例文字的样式
              color: "#000",
              fontSize: 10,
            },
          },
          dataZoom: [
            {
              type: "slider",
              start: 0,
              end: 100,
            },
            {
              type: "inside",
              start: 0,
              end: 100,
            },
          ],
          xAxis: {
            data: times,
          },
          yAxis: {
            type: "value"
          },

          series: [
            {
              data: list,
              type: "line",
              smooth: true,
            },
          ],
        })
      } else {
        this.$nextTick(() => {
          const dom = this.$refs.weekAllCharts;
          dom.innerHTML =
            '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
          dom.removeAttribute("_echarts_instance_");
        });
      }
    }
  }
}
</script>